var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"fullscreen":"","persistent":"","scrollable":"","max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"dialog-header",attrs:{"dark":""}},[_vm._v(" Deduct Force Leave "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"mt-4",staticStyle:{"max-height":"780px","max-width":"1300px","margin":"0 auto"}},[_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('v-select',{staticClass:"rounded-lg",attrs:{"label":"Year","outlined":"","color":"#519043","dense":"","items":_vm.years},on:{"change":function($event){return _vm.loadPlantillaEmployees()}},model:{value:(_vm.selectedYear),callback:function ($$v) {_vm.selectedYear=$$v},expression:"selectedYear"}}),_c('v-spacer'),_c('v-text-field',{staticClass:"rounded-lg",attrs:{"outlined":"","prepend-inner-icon":"search","label":"Search","single-line":"","hide-details":"","color":"#519043","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-card',{staticClass:"my-5 dt-container",attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.selectableEmployees,"items-per-page":10,"item-key":"empID","search":_vm.search,"options":_vm.options,"loading":_vm.loading,"hide-default-footer":"","show-select":""},on:{"update:options":function($event){_vm.options=$event},"pagination":_vm.pagination},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"ripple":false,"value":isSelected,"readonly":item.employee_status == 'Cannot be deducted' ||
                    item.usedFL == 5,"disabled":item.employee_status == 'Cannot be deducted' ||
                    item.usedFL == 5},on:{"input":function($event){return select($event)}}})]}},{key:"item.totalBal",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumberValue(item.totalBal, 3))+" ")]}},{key:"item.deductFL",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(5 - item.usedFL)+" ")]}}],null,true),model:{value:(_vm.selectedEmployee),callback:function ($$v) {_vm.selectedEmployee=$$v},expression:"selectedEmployee"}})],1),_c('v-row',{staticClass:"mb-2 mx-5",attrs:{"align":"center"}},[_c('v-col',{staticClass:"mr-auto text-truncate flex-items",attrs:{"cols":"auto","no-gutters":""}},[_c('span',{staticClass:"px-2"},[_vm._v("Show")]),_c('span',[_c('v-select',{staticClass:"rounded-lg",staticStyle:{"max-width":"90px"},attrs:{"dense":"","outlined":"","hide-details":"","value":_vm.options.itemsPerPage,"color":"#519043","items":_vm.perPageChoices},on:{"change":function($event){_vm.options.itemsPerPage = parseInt($event, 10)}}})],1),_c('span',{staticClass:"px-2"},[_vm._v(" Entries ")])]),_c('v-col',{staticClass:"mr-auto text-truncate",attrs:{"cols":"auto","no-gutters":""}},[_vm._v(" Showing "+_vm._s(_vm.paginationData.pageStart + 1)+" to "+_vm._s(_vm.paginationData.pageStop)+" of "+_vm._s(_vm.paginationData.itemsLength)+" entries ")]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-pagination',{staticClass:"rounded-lg",attrs:{"total-visible":7,"color":"#519043","length":_vm.paginationData.pageCount},model:{value:(_vm.options.page),callback:function ($$v) {_vm.$set(_vm.options, "page", $$v)},expression:"options.page"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pa-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","outlined":""},on:{"click":function($event){_vm.close();
            _vm.dialog = false;}}},[_c('v-icon',[_vm._v("mdi-close-circle-outline")]),_vm._v(" Cancel ")],1),_c('v-btn',{staticClass:"white--text",attrs:{"color":"#519043"},on:{"click":function($event){return _vm.deductFL()}}},[_c('v-icon',[_vm._v("mdi-check-circle")]),_vm._v(" SUBMIT ")],1)],1)],1)],1),_c('fade-away-message-component',{attrs:{"displayType":"variation2","message":_vm.fadeAwayMessage.message,"header":_vm.fadeAwayMessage.header,"top":_vm.fadeAwayMessage.top,"type":_vm.fadeAwayMessage.type},model:{value:(_vm.fadeAwayMessage.show),callback:function ($$v) {_vm.$set(_vm.fadeAwayMessage, "show", $$v)},expression:"fadeAwayMessage.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }